import { PAGE_PERMISSION } from './PagePermission';
import IntlMessages from '../util/IntlMessages';
import React from 'react';

export const ANALYTIC_MENU = [
    {
        id: 1,
        key: 'menu1',
        title: <IntlMessages id="app.sidebar.rideSummary" />,
        analyticsSubMenu: [
            {
                id: PAGE_PERMISSION.ANALYTICS,
                key: 'Summary',
                title: <IntlMessages id="app.sidebar.summary" />,
                path: "/e-scooter/analytics/summary"
            },
            {
                id: PAGE_PERMISSION.ANALYTICS,
                key: 'Chart',
                title: <IntlMessages id="app.sidebar.chart" />,
                path: "/e-scooter/analytics/chart"
            }
        ]
    }
]